.privacy_visible {
  display: block;
}

.hidden {
  display: none;
}

.privacy_page {
  width: 100%;
}

.privacy_container {
  position: relative;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
}

.privacy_header {
  padding: 16px 0;
}

.privacy_header .privacy_title {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  margin: 0;
}

.translations-list-container {
  padding-bottom: 8px;
  margin: 0 0 16px;
}

.translations-list-container .translations-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.translations-list-container .translations-list .translations-list-item {
  display: inline-block;
  padding: 0;
  margin: 0 8px 8px 0;
  font-weight: 700;
  color: #553df4;
}

.translations-list-container .translations-list .translations-list-item a {
  display: inline-block;
  color: #553df4;
  border: 1px solid #553df4;
  border-radius: 32px;
  padding: 4px 16px;
}

.translations-content-container {
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.privacy_footer {
  border-top: 1px solid #eee;
  margin: 32px 0 0;
  padding: 16px 0;
}
